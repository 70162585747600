import React from "react"
import { Helmet } from "react-helmet"
import NavigationBar from "../components/Navbar"
import loadable from "@loadable/component"
const Footer = loadable(() => import("../components/footer3"))

export default function CookiePolicy() {
  return (
    <div className="mb-6">
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title>Privacy Policy</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="This Cookie Policy applies to our website. By using our website, you hereby consent to our Cookie Policy and agree to its terms."
        />
      </Helmet>
      <div className="sm:sticky top-0 z-50">
        <NavigationBar />
      </div>
      <div className="mt-6 container mx-auto px-5 md:px-7 lg:px-8 xl:px-10 pt-5 md:pt-8">
        <h1 className="tracking-wider text-2xl mb-4 xl:text-3xl text-gray-900 text-center">
          Cookie Policy
        </h1>

        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            The EnjoyAlgorithms Cookie Policy sets out how
            https://www.enjoyalgorithms.com/ may use cookies and related
            tracking technologies (collectively, “cookie(s)”) to assist you in
            accessing and navigating the EnjoyAlgorithms websites and services
            (collectively the “EnjoyAlgorithms Sites”). To make our websites and
            services work properly, we sometimes use cookies as well as related
            tracking technologies.
          </p>
        </div>

        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          What Are Cookies?
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            As is common practice with almost all professional websites this
            site uses cookies, which are tiny files that are downloaded to your
            computer, to improve your experience. This page describes what
            information they gather, how we use it and why we sometimes need to
            store these cookies.
          </p>
          <p className="mb-4">
            We will also share how you can prevent these cookies from being
            stored however this may downgrade or ‘break’ certain elements of the
            site's functionality. For more general information on cookies see
            the Wikipedia article on HTTP Cookies.
          </p>
        </div>
        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          How We Use Cookies?
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            We use cookies for a variety of reasons detailed below.
            Unfortunately in most cases there are no industry standard options
            for disabling cookies without completely disabling the functionality
            and features they add to this site. It is recommended that you leave
            on all cookies if you are not sure whether you need them or not in
            case they are used to provide a service that you use.
          </p>
          <p className="mb-4">
            We use the data we collect from cookies to assist you in accessing
            and navigating the EnjoyAlgorithms Sites. The use of cookies will
            improve your experience when you browse our EnjoyAlgorithms Sites,
            allows us to enhance the functionality, increases security, measures
            use and effectiveness and allows us to show you offers and content
            that may be of interest to you.
          </p>
          <p className="mb-4">
            We also use cookies for content measurement, audience insights,
            product development, and to assist in promotional and marketing
            efforts. We use both session cookies (which exist only until you
            close your web browser) and persistent cookies (which exist for a
            longer, specified period of time or until you delete them) on the
            EnjoyAlgorithms Sites.
          </p>
          <p className="mb-4">
            Cookies may be set by us (first-party cookies) or by another company
            at our request (third-party cookies). Cookies may also be set in
            connection with emails or newsletters you receive from us.
            Information about you that we may collect from cookies includes:
            your preferred language and location, browser information, operating
            system information, IP address, user behavior (including user
            acquisition information, websites visited, time spent on each
            website page, content requested, content consumed, etc.) and other
            related data.
          </p>
        </div>

        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Third Party Cookies
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            This site uses Google Analytics which is one of the most widespread
            and trusted analytics solution on the web for helping us to
            understand how you use the site and ways that we can improve your
            experience. These cookies may track things such as how long you
            spend on the site and the pages that you visit so we can continue to
            produce engaging content. For more information on Google Analytics
            cookies, see the official Google Analytics page.
          </p>
        </div>
        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Disabling Cookies
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            You can prevent the setting of cookies by adjusting the settings on
            your browser (see your browser Help for how to do this). Be aware
            that disabling cookies will affect the functionality of this and
            many other websites that you visit. Disabling cookies will usually
            result in also disabling certain functionality and features of the
            this site. Therefore it is recommended that you do not disable
            cookies.
          </p>
        </div>

        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          More Information
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            Hopefully that has clarified things for you and as was previously
            mentioned if there is something that you aren’t sure whether you
            need or not it’s usually safer to leave cookies enabled in case it
            does interact with one of the features you use on our site.
          </p>
          <p className="mb-4">
            However if you are still looking for more information then you can
            contact us through one of our preferred contact methods. Email:
            contact@enjoyalgorithms.com.
          </p>
        </div>
        <div className="mt-6">
          <Footer />
        </div>
      </div>
    </div>
  )
}
